.info-card {
    position: relative;
    float: left;
    width: 470px;
    margin-bottom: 20px;
    z-index: 11;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.info-card .social{
    height: 48px;
    min-height: 48px;
}

.info-card h2{
    min-height: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: 400!important;
}

.info-card .slide{
    position: relative;
    top: -40px;
    height: calc(50px + 20vh);
    max-height: 70px;
    z-index: -1;
}

.info-card .swiper-wrapper {
    top: -30px;
    left: 0;
    width: 100%;
    height: calc(200px + 20vh);
    max-height: 350px;
    object-fit: cover;
    object-position: center;
}

.info-card .swiper-wrapper img {
    height: 320px;
    object-fit: cover;
    width: 480px;
}

.buttons-info-card.flex-center{
    height: 60px;
    min-height: 60px;
}

.info-card .profile {
    padding: 0;
    position: relative;
    box-shadow: 0 3px 3px rgba(0,0,0,.3);
    background-color: var(--secondary-background-color-light);
    text-align: center;
    z-index: 10;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.info-card:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(135deg,var(--primary-color-shadow) 0%,rgba(246,184,70,.01) 100%);
    background: -webkit-linear-gradient(135deg,var(--primary-color-shadow) 0%,rgba(246,184,70,.01) 100%);
    background: linear-gradient(135deg,var(--primary-color-shadow) 0%,rgba(246,184,70,.01) 100%);
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
}

.info-card .profile .profile-content {
    padding: 10px;
    overflow: hidden;
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .02s ease 1s;
    -moz-transition: all .02s ease 1s;
    -webkit-transition: all .02s ease 1s;
    -o-transition: all .02s ease 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info-card .profile .image{
    margin: 80px 0 20px;
    position: relative;
    display: inline-block;
    width: 170px;
}

.info-card .profile .image img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-color: var(--primary-background-color);
    border: 3px solid var(--primary-background-color);
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
}

.info-card .subtitle{
    color: var(--selected-item-color);
    font-weight: 600;
}

.info-card .title {
    min-height: 32px;
    height: 32px;
    font-family: 'Lekton';
    font-size: 34px;
    line-height: 32px;
    font-weight: bold;
    color: rgba(255,255,255,.9);
    position: relative;
    top: -5px;
}


/* Mobile  */
@media screen and (max-width: 720px) {
    .info-card{
        width: 100%;
    }

    .info-card .profile{
        height: auto;
    }

    .info-card:after{
        display: none;
    }
}