.contact-form-container {
  width: 80vw;
  max-width: 550px;
  margin: 0 auto;
}

.contact-form-container .contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form-container h2 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  color: var(--text-color);
}

.form-group label {
  display: block;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 80px;
}

.submit-button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: var(--selected-item-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: var(--selected-item-color-dark);
}

.toggle-button {
  margin-top: 10px;
  padding: 10px 15px;
  border: 2px solid var(--secondary-background-color-lighter);
  background-color: var(--secondary-background-color-light);
  width: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.toggle-button:hover {
  background-color: var(--secondary-background-color-light);
}

/* Animation for showing and hiding */
.contact-form-container .meeting-div {
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden; /* Ensure content is clipped if necessary */
  opacity: 0; /* Start hidden */
  max-height: 0; /* Start collapsed */
  transition: opacity 0.5s ease, max-height 0.5s ease; /* Transition for opacity and height */
}

.contact-form-container .meeting-div.show {
  opacity: 1; /* Fully visible */
  max-height: 550px; /* Expand to the height of the content */
}

.contact-form-container .meeting-div.hide {
  opacity: 0; /* Fully hidden */
  max-height: 0; /* Collapse to zero height */
}

.confirmation-message {
  display: flex;
  align-items: center;
  margin-top: 20px; /* Added spacing from other elements */
  padding: 15px 20px; /* Added padding for a better look */
  border: 1px solid #d4edda; /* Light green border to match success color */
  border-radius: 8px; /* Rounded corners */
  background-color: #d4edda; /* Light green background for success */
  color: #155724; /* Dark green text color for readability */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.check-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; /* Spacing between icon and text */
}

.check-icon {
  color: #28a745; /* Green color for the checkmark */
  font-size: 24px; /* Size of the checkmark icon */
}

.confirmation-text {
  flex: 1; /* Make sure text takes up the remaining space */
}

.confirmation-text p {
  margin: 0;
  font-size: 16px; /* Font size for the message text */
}
