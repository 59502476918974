.resume .two-columns{
  display: -webkit-box;  /* Safari, iOS, Android browser */
  display: -moz-box;     /* Firefox (old) */
  display: -ms-flexbox;   /* Internet Explorer 10 */
  display: -webkit-flex;  /* Safari 8+ (new) and Chrome */
  display: flex;          /* Modern browsers */
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  min-height: 800px;
}

.resume ul{
    padding-top: 10px;
    padding-right: 10px;
}

.resume ul li{
  display: -webkit-box;  /* Safari, iOS, Android browser */
  display: -moz-box;     /* Firefox (old) */
  display: -ms-flexbox;   /* Internet Explorer 10 */
  display: -webkit-flex;  /* Safari 8+ (new) and Chrome */
  display: flex;          /* Modern browsers */
  justify-content: space-evenly;
  padding-bottom: 10px;
  width: 280px;
  border-left: 2px solid var(--secondary-background-color-lighter);
  padding-left: 8px;
}

.resume ul li .right{
    padding: 8px;
    display: -webkit-box;  /* Safari, iOS, Android browser */
    display: -moz-box;     /* Firefox (old) */
    display: -ms-flexbox;   /* Internet Explorer 10 */
    display: -webkit-flex;  /* Safari 8+ (new) and Chrome */
    display: flex;          /* Modern browsers */
    justify-content: space-evenly;
    align-items: center;
}

.resume ul li .right img {
    max-width: 100px;
    filter: drop-shadow(2px 4px 6px black);
    margin: auto;
    background: white;
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 1000px;
    padding: 1px;
}

.resume .left{
    width: 100%;
    padding-right: 5px;
}

.resume h2,
.resume h3,
.resume h4,
.resume h5,
.resume h6
{
    font-size: 14px;
}

.resume .date{
    border: 2px solid var(--secondary-background-color-lighter);
    font-size: 12px;
    padding: 3px;
    border-radius: 6px;
}

.resume .date.highlighted{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-background-color-light);
    font-weight: 800;
}

.resume h1,
.resume h2.h1-title{
    padding-left: 10px;
    font-size: 16px;
}

.resume li.title-h2{
    padding-bottom: 2px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: var(--primary-color);
    font-weight: bold;
    line-height: 1.6;
    justify-content: left;
    border-bottom: 2px solid var(--secondary-background-color-lighter);
    border-top: 2px solid var(--secondary-background-color-lighter);
    margin-bottom: 2px;
}

.resume h2.name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.resume .skills-container{
    max-width: 580px;
    margin: auto;
}

.resume .skills-container .item-list{
    animation: fadeIn 1.5s;
}

/* Mobile  */
@media screen and (max-width: 1040px) {
    .resume .two-columns{
        flex-wrap: wrap;
        justify-content: center;
    }

    .resume h1,
    .resume h2.h1-title{
        display: flex;
        justify-content: center;
    }

    .resume li.title-h2,
    .card-wrap,
    .card-wrap .content{
        min-width: 100%;
    }
}