:root {
  --primary-color: #6ec1e4;
  --primary-color: #6daaf5;
  --primary-color-shadow: #6daaf560;
  --secondary-color: #54595f;
  /* --hover-item-color: #2788ff; */
  --hover-item-color: #6daaf5;
  /* --selected-item-color: #187efc; */
  /* --selected-item-color: #bad8fc; */
  --selected-item-color: #6daaf5;
  --selected-item-color-dark: #407dc2;
  --accent-color: #ffffff;
  --primary-background-color: #0f0f0f;
  --secondary-background-color-light: #222;
  --secondary-background-color-lighter: #292929;
  --secondary-background-color-dark: rgba(0,0,0,.5);
  --text-color: #d1d1d1;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: var(--secondary-background-color-dark);
}


body main ::-webkit-scrollbar {
  width: 6px;
  background-color: var(--secondary-background-color-dark);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  max-width: 100vw;
}

body, h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

ul::marker, 
li::marker,
ol::marker {
  display: none;
  font-size: 0px;
}

html, body {
  font-family: 'Lekton', sans-serif;
  background-color: var(--primary-background-color);
  color: var(--text-color);
  overflow-x: hidden;
  max-width: 100vw;
  font-size: 16px;
  line-height: 1.6;
  border: none;
  height: 100%;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  font-family: 'Poppins', sans-serif;
  color: var(--primary-color);
}

h3{
  font-family: 'Poppins', sans-serif;
  color: var(--secondary-color);
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

.flex-center{
  display: -webkit-box;  /* Safari, iOS, Android browser */
  display: -moz-box;     /* Firefox (old) */
  display: -ms-flexbox;   /* Internet Explorer 10 */
  display: -webkit-flex;  /* Safari 8+ (new) and Chrome */
  display: flex;          /* Modern browsers */
  justify-content: space-evenly;
  align-items: center;
}

.flex{
  display: -webkit-box;  /* Safari, iOS, Android browser */
  display: -moz-box;     /* Firefox (old) */
  display: -ms-flexbox;   /* Internet Explorer 10 */
  display: -webkit-flex;  /* Safari 8+ (new) and Chrome */
  display: flex;          /* Modern browsers */
  flex-wrap: wrap;
  justify-content: center;
}

.item-flex{
  width: 50%;
}

.space-evenly{
  justify-content: space-evenly;
  align-items: center;
}

.bold{
  font-weight: bold;
  font-family: 'Lekton';
  color: white;
}

html body div .no-h{
  font-family: 'Lekton', sans-serif;
  color: var(--text-color);
}

/* Mobile  */
@media screen and (max-width: 1040px) {
  .item-flex{
    width: 100%;
  }
}