.show-credential-button .icon{
    padding: 8px;
}

.show-credential-button a{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-background-color-light);
    font-weight: 800;
    font-size: 12px;
    padding: 3px;
    border-radius: 6px;
}