/* Keyframes for different browsers */
@-webkit-keyframes dropdowncard {
    0%   {
        top: -50px; 
        opacity: 0;
    }
    100% {
        top: 0px; 
        opacity: 95;
    }
}

@-moz-keyframes dropdowncard {
    0%   {
        top: -50px; 
        opacity: 0;
    }
    100% {
        top: 0px; 
        opacity: 95;
    }
}

@-o-keyframes dropdowncard {
    0%   {
        top: -50px; 
        opacity: 0;
    }
    100% {
        top: 0px; 
        opacity: 95;
    }
}

@keyframes dropdowncard {
    0%   {
        top: -50px; 
        opacity: 0;
    }
    100% {
        top: 0px; 
        opacity: 0.95;
    }
}

.card-wrap {
    opacity: 0.95;
    max-height: 480px;
    min-height: 480px;
    padding: 30px 30px 0;
    height: 100%;
    overflow: auto;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    overflow-x: hidden;
    position: relative;
    background-color: var(--secondary-background-color-light);
    z-index: 10;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
    max-width: calc(20% + 400px);
    -webkit-animation: dropdowncard 2s;
    -moz-animation: dropdowncard 2s;
    -o-animation: dropdowncard 2s;
    animation: dropdowncard 2s;
}

.card-wrap .title {
    font-size: 20px;
    padding-bottom: 30px;
}

.card-wrap .description {
    padding: 10px 0px;
}

.card-wrap .info-list ul li strong:after {
    position: relative;
    content: ' . . . .';
    font-weight: 400;
    display: inline;
    margin-left: -3px;
    z-index: 2;
    font-size: 10px;
}

.card-wrap .info-list ul li strong {
    margin-right: 8px;
    padding: 0;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}

.row .col {
    padding: 30px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.card-wrap .pricing{
    max-width: 400px;
}

.card-wrap .subs-button {
    font-family: 'Lekton';
    background: var(--selected-item-color);
    color: var(--primary-background-color);
    text-transform: uppercase;
    font-weight: 800;
    padding: 5px 25px;
    border-radius: 40px;
    box-shadow: 0 3px 3px rgba(0,0,0,.3);
}

.card-wrap .pricing .amount {
    margin: 0 0 30px;
}

.card-wrap .pricing .amount .dollar, 
.card-wrap .pricing .amount .period {
    margin: 0 3px;
    position: relative;
    bottom: 5px;
    display: inline-block;
    vertical-align: top;
    font-weight: 400;
}

.card-wrap .pricing .amount .period {
    bottom: auto;
    top: 5px;
    vertical-align: bottom;
}

.card-wrap .pricing .amount .dollar{
    color: white;
}

.card-wrap .pricing .name{
    margin: 0 0 20px;
    font-size: 17px;
    font-weight: 600;
}

.card-wrap .pricing .amount .number {
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 42px;
    line-height: 32px;
    font-weight: 600;
    color: white;
}

.card-wrap .title:after {
    content: '';
    width: 100%;
    position: absolute;
    left: -30px;
    right: 0;
    margin-top: 50px;
    height: 1px;
    background: -moz-radial-gradient(left, ellipse cover, rgba(197, 202, 213, .15) 0%, rgba(255, 255, 255, 0) 70%);
    background: -webkit-radial-gradient(left, ellipse cover, rgba(197, 202, 213, .15) 0%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(ellipse at left, rgba(197, 202, 213, .15) 0%, rgba(255, 255, 255, 0) 70%);
}

.card-wrap .content:last-child {
    margin-bottom: 30px;
}

.card-wrap .row {
    margin: 0 -30px;
    position: relative;
}

.card-wrap .row .col {
    padding: 30px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.card-wrap .service-items .service-item {
    text-align: left;
}

.card-wrap .service-items .service-item .icon {
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
    font-size: 32px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -khtml-border-radius: 60px;
    fill: var(--selected-item-color);
    color: var(--selected-item-color);
    border-color: var(--selected-item-color);
    background: transparent !important;
    border: 2px solid var(--selected-item-color);
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-wrap .pt1 {
    padding-top: 15px;
}

.card-wrap .info-list ul {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.card-wrap .info-list ul li {
    padding: 0 0 5px;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    text-align: left;
}

.card-wrap .light{
    font-weight: 400;
    color: var(--text-color);
}

/* Tablet */
@media screen and (max-width: 1024px) {
    .card-wrap {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: inherit;
    }

    .card-wrap .content {
        max-width: 480px;
        margin: auto;
    }

    .card-wrap .pricing {
        max-width: 100%;
    }
}


/* desktop */
@media screen and (min-width: 1024px) {
    .card-wrap .row .col.col-d-6 {
        width: 49.998%;
    }
}