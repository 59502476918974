.resume .charts-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 100%;
  justify-content: space-around;
}

.resume .charts-container h6{
  text-align: center;    
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.resume .charts-container .percentage{
  position: relative;
  left: calc(50% - 13px);
  bottom: calc(23px + 50%);
}

.resume .charts-container .percentage span{
  position: absolute;
}

.resume .charts-container .chart-item {
  width: 45%;
  margin: 10px 0;
}

@media (min-width: 768px) {

  .resume .charts-container .chart-item {
    width: 30%;
  }
}