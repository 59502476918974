.blog h1{
    padding: 10px;
}

.blog h2,
.blog h3,
.blog h4,
.blog h5,
.blog h6,
.blog p
{
    font-size: 14px;
}

.blog .showMore{
    padding: 10px
}

.blog .paypalcoofee{
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
}

.blog .paypalcoofee div{
    padding-left: 10px;
}

.blog .posts-contents-blog{
    max-height: 160px;
    overflow-y: scroll;
    margin-bottom: 10px;
    background-color: var(--secondary-background-color-lighter);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -khtml-border-radius: 10px;
    padding: 10px;
}

.blog a.one-page-menu-item{
    color: var(--primary-color);
    font-weight: bold;
}